import axios from 'axios';
import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import endpoints from '../endpoints';
import Loader from '../components/Loader';
import { toast } from 'react-hot-toast';

const AuthenticationContext = createContext();

const AuthenticationProvider = (props) => {
    const [loadingInitial, setLoadingInitial] = useState(true);

    const [user, setUser] = useState({});

    const navigate = useNavigate();

    const getUserData = async (Id) => {
        const response = await axios.get(endpoints.profile.get + Id);
        const userData = {
            dp: response.data.responseData.DP,
            name: response.data.responseData.PARTICIPANT_NAME,
            email: response.data.responseData.PARTICIPANT_EMAIL,
            id: response.data.responseData.PARTICIPANT_ID,
            username:
                response.data.responseData.PARTICIPANT_EMP_NO ??
                response.data.responseData.EMP_NO,
        };
        setUser(userData);
    };

    useEffect(() => {
        let token = localStorage.getItem('token');

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        // Interceptor
        axios.defaults.baseURL = 'http://134.209.157.187:8070/api/'; //Dev
        // axios.defaults.baseURL = 'http://159.65.157.4:8070/api/';
        // axios.defaults.baseURL = 'http://143.110.247.32:8070/api/';
        // axios.defaults.baseURL = 'http://64.227.142.128:8070/api/'; //sales
        // axios.defaults.baseURL = 'http://192.168.1.49:8070/api'; //local
        // axios.defaults.baseURL = 'http://159.65.157.4:8070/api'; //test

        const axiosId = axios.interceptors.response.use(
            (res) => {
                return res;
            },
            (err) => {
                switch (err?.response?.status) {
                    case 401:
                        const originalRequest = err.config;

                        if (!originalRequest.retry) {
                            originalRequest.retry = true;
                            let refreshToken =
                                localStorage.getItem('refreshToken');
                            let body = { refreshToken: refreshToken };

                            return axios
                                .post(endpoints.auth.token, body)
                                .then((response) => {
                                    axios.defaults.headers.common[
                                        'Authorization'
                                    ] = `Bearer ${response.data.token}`;
                                    originalRequest.headers[
                                        'Authorization'
                                    ] = `Bearer ${response.data.token}`;

                                    return axios(originalRequest);
                                })
                                .catch((err) => {
                                    localStorage.clear();
                                    setUser({});
                                    navigate('/login');
                                });
                        }
                        break;
                    case 403:
                        localStorage.clear();
                        setUser({});
                        navigate('/login');
                        throw err;
                    default:
                        throw err;
                }
            }
        );

        const userId = localStorage.getItem('userId');

        getUserData(userId)
            .then(() => {
                setLoadingInitial(false);
            })
            .catch((err) => {
                setUser({});
                localStorage.clear();
                setLoadingInitial(false);
                navigate('/login');
            });

        return () => {
            axios.interceptors.response.eject(axiosId);
        };
    }, []);

    const login = useCallback(async (userCredential) => {
        const response = await axios.post(endpoints.auth.login, userCredential);

        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${response.data.token}`;

        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
            'userId',
            response.data.responseData.PARTICIPANT_ID
        );

        const userData = {
            dp: response.data.responseData.DP,
            name: response.data.responseData.PARTICIPANT_NAME,
            email: response.data.responseData.PARTICIPANT_EMAIL,
            id: response.data.responseData.PARTICIPANT_ID,
            username:
                response.data.responseData.PARTICIPANT_EMP_NO ??
                response.data.responseData.EMP_NO,
        };

        setUser(userData);

        return response.data.responseData;
    }, []);

    const logout = useCallback(() => {
        axios
            .post(endpoints.auth.logout, {
                refreshToken: localStorage.getItem('refreshToken') || '',
            })
            .catch((err) => {
                toast.error('Something went wrong while logging out');
            })
            .finally(() => {
                localStorage.clear();
                navigate('login');
                setUser({});
            });
    }, []);

    return (
        <AuthenticationContext.Provider
            value={{
                user,
                setUser,
                login,
                logout,
                getUserData,
            }}
        >
            {loadingInitial ? <Loader /> : props.children}
        </AuthenticationContext.Provider>
    );
};

const useAuthenticationState = () => {
    let context = useContext(AuthenticationContext);
    if (context === undefined)
        throw new Error(
            'useAuthenticationState must be used within a AuthenticationProvider'
        );
    return context;
};

export { useAuthenticationState, AuthenticationProvider };
